import React from "react";
import Footer from "../partials/Footer";
import constants from "../epn-details/Constants";
import FooterDetails from "../epn-details/FooterDetails";

const SpecFooter = () => {
  return (
    <Footer
      companyName={constants.name}
      logo={constants.logo}
      disclaimer={constants.copyrightOrDisclaimer}
      columns={FooterDetails.columns}
      social={constants.social}
    />
  );
};

export default SpecFooter;
