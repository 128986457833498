import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Header({ details, logo }) {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const getLinks = (mobile = false) => {
    const linkClass = mobile
      ? "flex text-gray-300 hover:text-gray-200 py-2"
      : "text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out";
    return details.mainLinks.map((d, index) => {
      return (
        <li key={`item${index}`}>
          <Link to={d.to} className={linkClass}>
            {d.title}
          </Link>
        </li>
      );
    });
  };

  const getLoginLink = (mobile = false) => {
    const linkClass = mobile
      ? "font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
      : "btn-sm text-white bg-purple-600 ml-3";
    return (
      details.configuration.showLogin && (
        <li>
          <Link to="/" className={linkClass}>
            Login
          </Link>
        </li>
      )
    );
  };

  const getPomeloLink = (mobile = false) => {
    const linkClass = mobile
      ? "font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
      : "btn-sm text-white bg-purple-600 ml-3";
    const pomeloLink = "https://www.pomelo.io/grants/epn";
    return (
      details.configuration.showPomelo && (
        <li>
            <a
            href={pomeloLink}
            className={linkClass}
            >
                <img
                    className="mr-3 w-3 h-5"
                    src={require("../images/PomeloLogo.png").default}
                    // width="1024"
                    // height="504"
                    alt="PomeloLogo"
                />
                Donate on Pomelo
            </a>
        </li>
        
      )
    );
  };

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="EOS Power Network">
              {logo}
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {getLinks()}
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {getLoginLink()}
            </ul>

            {/* Desktop Pomelo link */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {getPomeloLink()}
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-gray-800 px-4 py-2">
                {getLinks(true)}
                {getLoginLink(true)}
                {getPomeloLink(true)}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
