import React from "react";

const Quote = ({ children }) => {
  return (
    <blockquote className="italic pl-4 border-l-2 border-gray-200 mb-8">
      {children}
    </blockquote>
  );
};

export default Quote;
