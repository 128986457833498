const PowerCycleDetails = {
  header: {
    title: "The Power Cycle",
    subtitle: "The secret to rebooting DeFi on EOS",
  },
  body: [
    {
      tagline: "[ 1 ]",
      title: "Users Support Proxy",
      description:
        "After the initial distribution event, the only way to mine Power Network Tokens \
        is to stake EOS and delegate your vote to the Power Proxy.",
      image: require(`../images/PowerCycle-1.png`),
    },
    {
      tagline: "[ 2 ]",
      title: "Proxy Supports Operators",
      description:
        "The Power Proxy votes according to the consensus reached by the Power Network Contest. \
       This helps elevate Power Network Operator rankings in the EOS block producer elections.",
      image: require(`../images/PowerCycle-2.png`),
    },
    {
      tagline: "[ 3 ]",
      title: "Operators Support Orgs",
      description:
        "Power network operators service subscriptions, donations, and other transaction services \
        for companies and organizations.",
      image: require(`../images/PowerCycle-3.png`),
    },
    {
      tagline: "[ 4 ]",
      title: "Orgs Support Users",
      description:
        "In addition to the products and services organizations offer users, the revenue generated by \
        their utilization of the EOS Power Network is also distributed back to users.",
      image: require(`../images/PowerCycle-4.png`),
    },
  ],
};

export default PowerCycleDetails;
