import React from "react";
import { Link } from "react-router-dom";

function FeaturesBlocks({ sectionHeader, sectionDetails, cta }) {
  const getBlocks = () => {
    return sectionDetails.map((block, index) => {
      return (
        <div
          className="relative flex flex-col items-center"
          data-aos="fade-up"
          data-aos-delay={`${index * 100}`}
          data-aos-anchor="[data-aos-id-blocks]"
          key={`item${index}`}
        >
          <span className="text-purple-600">{block.icon}</span>

          <h4 className="h4 mb-2 mt-2">{block.header}</h4>
          <p className="text-lg text-gray-400 text-center">
            {block.description}
          </p>
        </div>
      );
    });
  };

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">{sectionHeader.title}</h2>
            <p className="text-xl text-gray-400">{sectionHeader.description}</p>
          </div>
          {/* Items */}
          <div
            className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
            data-aos-id-blocks
          >
            {getBlocks()}
          </div>
          <div
            className="flex justify-center mb-8"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-anchor="[data-aos-id-cta]"
          >
            <Link
              to={cta.href}
              className="btn text-white bg-purple-600 hover:bg-purple-700 mt-12"
            >
              {cta.btnText}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
