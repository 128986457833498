import React from "react";

//import RelatedArticles from "./RelatedArticles"; // Will be useful at some point when I make other blog articles.

// Blog bits
import SocialLinks from "./SocialLinks";

function BlogSingle({ content, social }) {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              {content}

              {/* Article footer */}
              <footer>
                <div className="md:flex md:items-center md:justify-between text-center md:text-left">
                  <div className="text-lg text-gray-400 italic">
                    {/* Originally published at{" "}
                    <Link to="https://medium.com/" className="text-gray-200">
                      medium
                    </Link>
                    . */}
                  </div>
                  <ul className="inline-flex mt-4 md:ml-4 md:mb-0">
                    <SocialLinks social={social} />
                  </ul>
                </div>
              </footer>
            </article>
          </div>

          {/* Related articles */}
          {/* <RelatedArticles /> Todo@Jmart once there are more blog articles on the power network*/}
        </div>
      </div>
    </section>
  );
}

export default BlogSingle;
