import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition.js";

function Tabs({ topComponent, sectionHeader, sectionDetails }) {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab - 1]) {
      tabs.current.style.height =
        tabs.current.children[tab - 1].offsetHeight + "px";
    }
  };

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const getButtons = () => {
    return sectionDetails.map((section, index) => {
      return (
        <button
          key={`item${index}`}
          className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${
            tab !== index + 1 && "opacity-50"
          }`}
          onClick={() => setTab(index + 1)}
        >
          <span className="text-purple-600 mr-2">{section.icon}</span>
          <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
            {section.btnTitle}
          </span>
        </button>
      );
    });
  };

  const getCardDetails = () => {
    return sectionDetails.map((section, index) => {
      return (
        <Transition
          key={`item${index}`}
          show={tab === index + 1}
          appear={true}
          className="w-full"
          enter="transition ease-in-out duration-500 transform order-first"
          enterStart="opacity-0 scale-98"
          enterEnd="opacity-100 scale-100"
          leave="transition ease-out duration-300 transform absolute"
          leaveStart="opacity-100 scale-100"
          leaveEnd="opacity-0 scale-98"
        >
          <article className="relative max-w-md mx-auto md:max-w-none">
            {/* Image side */}
            <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2 border-4 border-gray-800">
              <img
                className="w-full h-full object-cover"
                src={section.image.default}
                width="516"
                height="387"
                alt={`Tabs 0${index + 1}`}
              />
            </figure>

            {/* Text side */}
            <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
              <h4 className="h4 mb-2">{section.title}</h4>
              <p className="text-lg text-gray-400">{section.description} </p>

              {/* Learn more button */}
              {section.learnMoreHref && (
                <a
                  className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                  href={section.learnMoreHref}
                >
                  <span className="text-sm">Learn more</span>
                  <svg
                    className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 5H0v2h6v4l6-5-6-5z" />
                  </svg>
                </a>
              )}
            </div>
          </article>
        </Transition>
      );
    });
  };

  return (
    <section className={topComponent ? "relative" : ""}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div
          className={`py-12 md:py-20 ${
            topComponent
              ? "pt-32 md:pt-40 md:pb-20"
              : "border-t border-gray-800"
          }`}
        >
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            {topComponent ? (
              <h1
                className="h1 mb-4"
                data-aos="fade-up"
                data-aos-anchor="[data-aos-id-tabs]"
              >
                {sectionHeader.title}
              </h1>
            ) : (
              <h2
                className="h2 mb-4"
                data-aos="fade-up"
                data-aos-anchor="[data-aos-id-tabs]"
              >
                {sectionHeader.title}
              </h2>
            )}
            <p
              className="text-xl text-gray-400"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-tabs]"
            >
              {sectionHeader.description}
            </p>
          </div>

          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div
              className="flex flex-wrap justify-center -m-2"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-tabs]"
            >
              {getButtons()}
            </div>

            {/* Tabs items */}
            <div
              className="relative flex flex-col mt-16"
              data-aos="fade-up"
              ref={tabs}
            >
              {getCardDetails()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
