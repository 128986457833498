import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";

function Footer({ companyName, logo, disclaimer, columns, social }) {
  const getLinks = (links) => {
    return links.map((link, index) => {
      const linkClass =
        "text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out";

      return link.external ? (
        <li className="mb-1" key={`item${index}`}>
          <a href={link.href} className={linkClass}>
            {link.title}
          </a>
        </li>
      ) : link.download ? (
        <li className="mb-1" key={`item${index}`}>
          <Link to={link.href} className={linkClass} target="_blank" download>
            {link.title}
          </Link>
        </li>
      ) : (
        <li className="mb-1" key={`item${index}`}>
          <Link to={link.href} className={linkClass}>
            {link.title}
          </Link>
        </li>
      );
    });
  };

  const getColumns = () => {
    return (
      // Need to change grid-cols-3  if i change the number of columns in footerdetails :(
      <div className={`md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8`}>
        {columns.map((column, index) => {
          return (
            <div className="text-sm" key={`item${index}`}>
              <h6 className="text-gray-200 font-medium mb-1">
                {column.header}
              </h6>
              <ul>{getLinks(column.links)}</ul>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label={companyName}>
                  {logo}
                </Link>
              </div>
              <div className="text-gray-400">{disclaimer}</div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            {getColumns()}
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              {<SocialLinks social={social} />}
            </ul>

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
