import React from "react";
import BlogHeader from "../../partials/blog-bits/BlogHeader";
import BlogHeaderImage from "../../partials/blog-bits/BlogHeaderImage";
import Para from "../../partials/blog-bits/Para";
import Quote from "../../partials/blog-bits/Quote";
import BlogH3 from "../../partials/blog-bits/BlogH3";
import BlogH4 from "../../partials/blog-bits/BlogH4";
import Bold from "../../partials/blog-bits/Bold";
import BlogList from "../../partials/blog-bits/BlogList";
//import BlogImage from "../../partials/blog-bits/BlogImage";
//import BlogLink from "../../partials/blog-bits/BlogLink";
import Bli from "../../partials/blog-bits/Bli";

const Tokenomics = () => {
  return (
    <div>
      <BlogHeader
        title="Tokenomics Overview"
        excerpt="The EOS Power Network acknowledges the
        tradeoff between power and profit."
      />

      <BlogHeaderImage
        src={require("../../images/tokenomics.jpg")}
        alt="News single"
      />

      <div className="text-lg text-gray-400">
        <BlogH3>EOS Power Network Tokens</BlogH3>
        <Para>
          There are two tokens in the EOS Power Network. The yield token, and
          the power token. The yield token gives the staker rights to receive a
          pro rata portion of the revenue generated by the network, whereas the
          power token gives the staker the right to a pro-rata portion of vote
          weight in the Power Contest. More details about voting in the Power
          Contest may be found in the whitepaper.
        </Para>
        <BlogH3>Shared Supply</BlogH3>
        <Para>
          These two tokens share a total supply, and the relative supply of each
          of them is up to the market-determined preference ratio of power vs
          profit. To achieve this, the tokens may be swapped 1:1 with each other
          in the token contract. As market preference favors power, profit per
          yield token will increase. As market preference favors profit, vote
          weight per power token will increase.
        </Para>

        <BlogH3>Inflation</BlogH3>
        <Para>The whitepaper explains:</Para>
        <Quote>
          After the initial token distribution, the power tokens' combined
          supply will inflate at 5% per year. The new tokens will be distributed
          to those who proxy their vote to the Power Proxy. ...This is
          effectively a 5% annual redistribution of power and profit to those
          who are delegating their EOS vote to the Power Proxy.
        </Quote>
        <Para>
          By default, all issued tokens are yield tokens. If desired, anyone may
          swap the yield token with the power token if they prefer political
          influence.
        </Para>

        <BlogH3>Staking</BlogH3>
        <Para>
          Staking the power network tokens is required to receive the
          corresponding benefits. Staking works the same way as staking the EOS
          token and locks the staked tokens (so they are no longer liquid).
          Unstaking either token takes 30 days. Though staked tokens cannot be
          traded, they may be swapped. This means that power tokens may be
          exchanged for yield tokens, and vice versa, at any time, even when the
          tokens are staked. When a staked token is swapped into the other type
          of Power Network token, the resulting token is still staked.
        </Para>

        <BlogH3>Mining Power Network Tokens</BlogH3>
        <Para>
          To mine power network tokens, an EOS account must simply stake EOS and
          delegate their vote weight to the EOS Power Network proxy, the Power
          Proxy. This does not necessarily mean they give up their right to vote
          on EOS block producers, as anyone may stake Power Tokens to
          participate in the Power Contest to influence the block producers
          voted for by the Power Proxy.
        </Para>

        <BlogH3>Power Contest</BlogH3>
        <Para>
          The Power Contest determines who the Power Proxy will vote for in the
          EOS Block Producer election. Anyone may vote in the power contest, and
          their total vote weight is proportional to that account’s staked power
          tokens. For more details regarding the rules of the power contest, see
          the whitepaper.
        </Para>

        <BlogH3>Yield Token Revenue</BlogH3>
        <Para>
          All revenue collected by the EOS Power Network, including the revenue
          from the token distribution event, is collected in a smart contract
          which will release funds daily to reward yield token stakers. 0.15% of
          all revenue available in the EOS Power Network smart contract
          distributed daily to yield token stakers, proportional to the amount
          of yield tokens staked. See the whitepaper for more details.
        </Para>

        <BlogH3>Economic Design Justification</BlogH3>
        <BlogH4>On Inflation</BlogH4>
        <Para>
          A 5% inflation rate is used as it achieves almost a 100%
          redistribution over the course of 100 years. In other words, each long
          human lifespan the EOS Power Network will have fully redistributed its
          power and profit potential to the future generations.
        </Para>
        <BlogH4>On the Power / Profit Tradeoff</BlogH4>
        <Para>
          Forcing users to make an explicit choice between power and short-term
          profit is an intentional design which will have several intended
          effects.
        </Para>
        <BlogList numbering="true">
          <Bli>
            It will centralize the consensus of the Power Proxy into the hands
            of those for whom the long-term capital-gains and other long-term
            value proposition of EOS means more than the short-term revenue
            gains. Those controlling the EOS Power Network proxy vote are
            therefore the entities most aligned with the long-term success of
            EOS.
          </Bli>
          <Bli>
            It will maximize the reward for those seeking short-term profit.
          </Bli>
        </BlogList>

        <BlogH3>The Importance of Power Network Token Mining</BlogH3>
        <Para>
          Ideally, EOS “whales” should be able to maximize their short-term
          profit by delegating their vote to the Power Proxy and staking yield
          tokens, rather than delegating their vote weight to rewards proxies.
          It is possible that expected yield from delegating to the EPN will
          exceed the returns of voting for other rewards proxies, because EPN
          yield is <Bold>not</Bold> fueled by EOS inflation, but by its own
          premium services and can therefore compete with exchanges and other
          block producing entities subsidizing EOS Voter Rewards.
        </Para>
        <Para>
          Power Network token mining has significant benefits for the EOS public
          blockchain:
        </Para>
        <BlogList numbering="true">
          <Bli>
            Accumulating and staking EOS results in decreased EOS liquidity and
            subsequent upward price pressure on EOS. Upward price pressure is a
            natural way to market EOS, and also increases the security of EOS by
            raising the capital threshold of attack.
          </Bli>
          <Bli>
            Power Network token mining results in increased political power of
            the Power Proxy. The Power Proxy returns voting power back to the
            people most aligned with the long-term success of EOS, whereas the
            voting criteria for alternative rewards proxies is opaque,
            centralized, and not necessarily aligned with long-term benefits to
            EOS.
          </Bli>
        </BlogList>

        <BlogH3>Conclusion</BlogH3>
        <Para>
          The Power Network tokens are simple and the mechanics are designed to
          maximize benefit to the EOS public blockchain. The Power Network
          tokens are critical to the alignment of all parties within the EOS
          Power Network. See the whitepaper to learn more.
        </Para>
      </div>
    </div>
  );
};

export default Tokenomics;
