import React from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { IconContext } from "react-icons";

const SocialLinks = ({ social }) => {
  const { twitter, telegram } = social;

  const makeLink = (icon, socialDetails) => {
    return (
      <li className="ml-4">
        <a
          href={socialDetails.link}
          className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
          aria-label={socialDetails.title}
        >
          <IconContext.Provider value={{ className: "w-8 h-8 p-2" }}>
            <div>{icon}</div>
          </IconContext.Provider>
        </a>
      </li>
    );
  };

  return (
    <>
      {twitter && makeLink(<FaTwitter size={14} />, twitter)}
      {telegram && makeLink(<FaTelegramPlane size={14} />, telegram)}
    </>
  );
};

export default SocialLinks;
