import React from "react";

function FeaturesZigzag({ topComponent, details }) {
  const getItems = () => {
    return details.body.map((item, index) => {
      const evenIndex = index % 2 === 0;

      return (
        <div
          className="md:grid md:grid-cols-12 md:gap-6 items-center"
          key={`item${index}`}
        >
          {/* Image */}
          <div
            className={`max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 ${
              evenIndex ? "md:order-1" : "md:rtl"
            }`}
            data-aos="fade-up"
          >
            <img
              className="max-w-full mx-auto md:max-w-none h-auto"
              src={item.image.default}
              width="540"
              height="233"
              alt="Features 01"
            />
          </div>
          {/* Content */}
          <div
            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
            data-aos={evenIndex ? "fade-right" : "fade-left"}
          >
            <div
              className={
                evenIndex
                  ? "md:pr-4 lg:pr-12 xl:pr-16"
                  : "md:pl-4 lg:pl-12 xl:pl-16"
              }
            >
              <div className="font-architects-daughter text-xl text-purple-600 mb-2">
                {item.tagline}
              </div>
              <h3 className="h3 mb-3">{item.title}</h3>
              <p className="text-xl text-gray-400 mb-4">{item.description}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <section className={topComponent ? `relative` : ``}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div
          className={`py-12 md:py-20 ${
            topComponent
              ? "pt-32 md:pt-40 md:pb-20"
              : "border-t border-gray-800"
          }`}
        >
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            {topComponent ? (
              <h1 className="h1 mb-4">{details.header.title}</h1>
            ) : (
              <h2 className="h2 mb-4">{details.header.title}</h2>
            )}
            <p className="text-xl text-gray-400">{details.header.subtitle}</p>
          </div>

          {/* Items */}
          <div className="grid gap-20">{getItems()}</div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;
