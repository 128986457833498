import React, { useState } from "react";

import PageIllustration from "../partials/PageIllustration";
import Whitepaper from "../partials/Whitepaper";
import SpecFooter from "../specifics/SpecFooter";
import faqDetails from "../epn-details/FaqDetails";
import SpecHeader from "../specifics/SpecHeader";

const { lastUpdatedDate, categories } = faqDetails;

function Faq() {
  const [page, setPage] = useState(1);

  const getQuestions = (category) => {
    return category.questions.map((question) => {
      return (
        <li className="py-4">
          <h4 className="text-xl font-medium mb-2">{question.title}</h4>
          <p className="text-lg text-gray-400">{question.answer}</p>
        </li>
      );
    });
  };

  const getPages = () => {
    return categories.map((category, index) => {
      return (
        <div className={page !== index + 1 ? "hidden" : undefined}>
          <div className="mb-8">
            <h2 className="h2 mb-4">{category.title}</h2>
            <p className="text-gray-400">
              Last updated -{" "}
              <span className="text-purple-600">{lastUpdatedDate}</span>
            </p>
          </div>
          <ul className="-my-4">{getQuestions(category)}</ul>
        </div>
      );
    });
  };

  const getNav = () => {
    return categories.map((c, index) => {
      return (
        <li className="py-2 border-b border-gray-800">
          <a
            className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${
              page === index + 1 && "text-purple-600"
            }`}
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              setPage(index + 1);
            }}
          >
            <span>{c.title}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <SpecHeader />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="flex flex-col md:flex-row">
              {/* Main content */}
              <main
                className="md:flex-auto md:pl-10 order-1"
                data-aos="fade-up"
              >
                {getPages()}
              </main>
              {/* Nav sidebar */}
              <aside
                className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4 className="text-lg font-medium px-3 pb-3 border-b border-gray-800">
                  Choose a category
                </h4>
                <nav>
                  <ul>{getNav()}</ul>
                </nav>
              </aside>
            </div>
          </div>
        </div>
      </main>

      <Whitepaper />
      {/*  Site footer */}
      <SpecFooter />
    </div>
  );
}

export default Faq;
