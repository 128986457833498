import React from "react";

import Timeline from "../partials/Timeline";
import SpecFooter from "../specifics/SpecFooter";
import Whitepaper from "../partials/Whitepaper";
import SpecHeader from "../specifics/SpecHeader";

function Roadmap() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <SpecHeader />

      <main className="flex-grow">
        <Timeline />
        <Whitepaper />
      </main>

      <SpecFooter />
    </div>
  );
}

export default Roadmap;
