import { GoPrimitiveDot } from "react-icons/go";

const howItWorksTabsDetails = {
  tabsHeader: {
    title: "How do pull transactions work?",
    description: "Learn by example (4 steps)",
  },
  tabsDetails: [
    {
      btnTitle: "1",
      title: "User upgrades",
      description: "Alice upgrades her EOS account to an EOS Power User.",
      icon: <GoPrimitiveDot />,
      image: require("../images/HowItWorks-1.png"),
    },
    {
      btnTitle: "2",
      title: "Company™ drafts a contract",
      description: "Company™ drafts a contract anyone may sign.",
      icon: <GoPrimitiveDot />,
      image: require("../images/HowItWorks-2.png"),
    },
    {
      btnTitle: "3",
      title: "User signs contract",
      description: "Alice signs the Company™ contract.",
      icon: <GoPrimitiveDot />,
      image: require("../images/HowItWorks-3.png"),
    },
    {
      btnTitle: "4",
      title: "EPN Services",
      description: "The Power Network facilitates the ongoing transfer.",
      icon: <GoPrimitiveDot />,
      image: require("../images/HowItWorks-4.png"),
    },
  ],
};

export default howItWorksTabsDetails;
