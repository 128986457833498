import React from "react";
import Header from "../partials/Header";
import headerDetails from "../epn-details/HeaderDetails";
import constants from "../epn-details/Constants";

const SpecHeader = () => {
  return <Header details={headerDetails} logo={constants.logo} />;
};

export default SpecHeader;
