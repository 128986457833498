const faqDetails = {
  title: "",
  lastUpdatedDate: "September 9, 2021",
  categories: [
    {
      title: "Category 1",
      questions: [
        {
          title: "Question 1",
          answer: "Answer 1",
        },
      ],
    },
    {
      title: "Category 2",
      questions: [
        {
          title: "Question 1",
          answer: "Answer 1",
        },
        {
          title: "Question 2",
          answer: "Answer 2",
        },
        {
          title: "Question 3",
          answer: "Answer 3",
        },
      ],
    },
  ],
};
export default faqDetails;
