import { AiOutlineBlock } from "react-icons/ai";
import {
  BsFillDropletFill,
  BsFillLockFill,
  BsFillPeopleFill,
} from "react-icons/bs";
import { GiFlexibleStar } from "react-icons/gi";
import { BiDollar } from "react-icons/bi";

const whyEOSTabsDetails = {
  tabsHeader: {
    title: "Why EOS?",
    description: "The future of decentralized finance needs EOS. Here's why.",
  },
  tabsDetails: [
    {
      btnTitle: "Secure",
      title: "Account permissions",
      description:
        "EOS accounts allow for more advanced account permissions management than other \
        blockchains. The EPN makes use of clever account permissions to offer unique \
        services without compromising security.",
      icon: <BsFillLockFill />,
      image: require("../images/WhyEOS-1.png"),
      learnMoreHref:
        "https://developers.eos.io/welcome/latest/protocol/accounts_and_permissions",
    },
    {
      btnTitle: "Flexible",
      title: "Multi-signature authentication",
      description:
        "Projects which require multiple authentication parties are easier \
        to build on EOS, since it natively supports multisig transactions. The EPN system account \
        is authenticated by a multisig of elected network operators.",
      icon: <GiFlexibleStar />,
      image: require("../images/WhyEOS-2.png"),
      learnMoreHref: "https://www.genereos.io/eosio-multisig-tutorial/",
    },
    {
      btnTitle: "Modular",
      title: "Modular block-producer nodes",
      description:
        "Plugins are used to easily extend the standard functionality of the core EOS \
        validator client, NodeOS. The EPN operators run specialized nodes which service \
        the needs of the EPN.",
      icon: <AiOutlineBlock />,
      image: require("../images/WhyEOS-3.png"),
      learnMoreHref:
        "https://developers.eos.io/manuals/eos/v2.2/nodeos/plugins/index",
    },
    {
      btnTitle: "Collaborative",
      title: "Thriving community",
      description:
        "Because EOS is a more established project than many of the other popular blockchains, there is a \
        passionate core community incentivized to work for the long-term health of the network.",
      icon: <BsFillPeopleFill />,
      image: require("../images/WhyEOS-4.png"),
      learnMoreHref: "https://t.me/EdenOSinfo",
    },
    {
      btnTitle: "Affordable",
      title: "Affordable transactions",
      description:
        "It would currently only cost 0.6 EOS per month to facilitate 1,000 \
      EOS token transfers per day! As the number of transactions serviced by \
      the EPN grows, affordable token transfers become increasingly \
      important.",
      icon: <BiDollar />,
      image: require("../images/WhyEOS-5.png"),
      learnMoreHref: "https://eos.io/news/eos-powerup-model-explained/",
    },
    {
      btnTitle: "Well-capitalized",
      title: "Highly liquid, highly capitalized",
      description:
        "Market capitalization is important for security, and EOS has the highest \
        market cap of any EOSIO blockchain. The EPN leverages this by electing \
        operators according to the EOS block producer elections.",
      icon: <BsFillDropletFill />,
      image: require("../images/WhyEOS-6.png"),
      learnMoreHref:
        "https://developers.eos.io/welcome/v2.2/protocol-guides/consensus_protocol/#41-voting-process",
    },
  ],
};

export default whyEOSTabsDetails;
