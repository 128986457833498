import React from "react";

const BlogHeaderImage = ({ src, alt }) => {
  return (
    <figure
      className="mb-8 lg:-ml-32 lg:-mr-32"
      data-aos="fade-up"
      data-aos-delay="600"
    >
      <img
        className="w-full"
        src={src.default}
        width="1024"
        height="576"
        alt={alt}
      />
    </figure>
  );
};

export default BlogHeaderImage;
