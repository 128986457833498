import React from "react";

function Timeline() {
  return (
    <section>
      {/* 
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 mb-4" data-aos="fade-up">
              The story behind the project
            </h1> */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 ">
        <div className="py-4 md:py-12">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1 mb-4">Roadmap</h1>
            <p className="text-xl text-gray-400">High-level milestones</p>
          </div>

          {/* Items */}
          <div
            className="max-w-3xl mx-auto -my-4 md:-my-6"
            data-aos-id-timeline
          >
            {/* 1st item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-purple-600 mb-2">
                  Concept
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2020
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">EOS Power Network conceptualized</h4>
                </div>
                <p className="text-lg text-gray-400">
                  Set of tradeoffs discovered which would allow a smart contract
                  protected by multi-sig to facilitate automatic transfers.
                </p>
              </div>
            </div>

            {/* 2nd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-purple-600 mb-2">
                  Token
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2022
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">
                    Start of the EPN token distribution event
                  </h4>
                </div>
                <p className="text-lg text-gray-400">
                  The token distribution will not begin until the first version
                  of the service is feature complete.
                </p>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-purple-600 mb-2">
                  Launch
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2022
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">Full platform launch</h4>
                </div>
                <p className="text-lg text-gray-400">
                  The platform will launch at the conclusion of the token
                  distribution event, enabling new capabilities on EOS.
                </p>
              </div>
            </div>

            {/* 4th item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="font-architects-daughter text-xl text-purple-600 mb-2">
                  Growth
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2023+
                  </div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4">EOS Power Cycle</h4>
                </div>
                <p className="text-lg text-gray-400">
                  Token accumulation &gt; EPN proxy gains influence &gt;
                  Attracts quality BPs &gt; Attracts services / dapps &gt; Yield
                  for Power Users
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;
