const statsSectionDetails = {
  statsHeader: {
    title: "Token distribution",
    description:
      "Every day for six months, tokens are distributed to users proportionally \
       to the amount of EOS contributed to the token contract that day.",
  },
  statsDetails: [
    {
      number: "100%",
      description:
        "of $EOS tokens raised are distributed back to the community.",
    },
    {
      number: "0%",
      description: "of token supply is witheld as a premine or reservation.",
    },
    {
      number: "100%",
      description:
        "of revenue generated by the network is distributed back to the community.",
    },
  ],
  statsCta: {
    text: "What does the token do?",
    href: "/tokenomics",
    btnText: "Learn more about the token",
  },
};

export default statsSectionDetails;
