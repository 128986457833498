import React from "react";
import { Link } from "react-router-dom";

function Stats({ sectionHeader, sectionDetails, cta }) {
  const getItems = () => {
    return sectionDetails.map((section, index) => {
      return (
        <div className="py-6 md:py-0 md:px-8" key={`item${index}`}>
          <div
            className="text-4xl font-bold leading-tight tracking-tighter text-purple-600 mb-2"
            data-aos="fade-up"
          >
            {section.number}
          </div>
          <div
            className="text-lg text-gray-400"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {section.description}
          </div>
        </div>
      );
    });
  };

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">{sectionHeader.title}</h1>
            <p className="text-xl text-gray-400">{sectionHeader.description}</p>
          </div>

          <div className="grid md:grid-cols-3 bg-gray-800 divide-y md:divide-y-0 md:divide-x divide-gray-700 px-6 md:px-0 md:py-8 text-center">
            {getItems()}
          </div>

          <div
            className="flex justify-center mb-8"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-anchor="[data-aos-id-cta]"
          >
            <Link
              to={cta.href}
              className="btn text-white bg-purple-600 hover:bg-purple-700 mt-12"
            >
              {cta.btnText}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
