import Logo from "./Logo";

const constants = {
  name: "The EOS Power Network",

  logo: <Logo />,

  social: {
    twitter: {
      title: "Twitter",
      link: "https://twitter.com/eospowernetwork",
    },
    // telegram: {
    //   title: "Telegram",
    //   link: "https://t.me/joinchat/vusyjx37V4IzNWMx",
    // },
  },

  copyrightOrDisclaimer:
    "The EOS Power Network is not owned or operated by any one entity.",

  iconSize: 42, // I think this is specifically for the icons in the feature block section.

  //Colors - so far I don't need this, actually.
  //textPurpleHighlight: "text-purple-600",
};

export default constants;
