import React from "react";

import PageIllustration from "../partials/PageIllustration";
import Whitepaper from "../partials/Whitepaper";
import Tabs from "../partials/Tabs";

import howItWorksTabsDetails from "../epn-details/HowItWorksTabsDetails";
import SpecFooter from "../specifics/SpecFooter";
import SpecHeader from "../specifics/SpecHeader";
const { tabsHeader, tabsDetails } = howItWorksTabsDetails;

function HowItWorks() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <SpecHeader />

      <main className="flex-grow">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <Tabs
          topComponent={true}
          sectionHeader={tabsHeader}
          sectionDetails={tabsDetails}
        />
        <Whitepaper />
      </main>

      {/*  Site footer */}
      <SpecFooter />
    </div>
  );
}

export default HowItWorks;
