const FooterDetails = {
  // If I change the # of columns here: I also need to change grid-cols-3 in Footer.js getColumns()
  columns: [
    {
      header: "Pages",
      links: [
        {
          title: "How it Works",
          href: "/how-it-works",
        },
        {
          title: "Power Cycle",
          href: "/power-cycle",
        },
        {
          title: "Tokenomics",
          href: "/tokenomics",
        },
        {
          title: "Roadmap",
          href: "/roadmap",
        },
      ],
    },
    {
      header: "Resources",
      links: [
        {
          title: "EOS Community",
          href: "https://eoscommunity.org/",
          external: true,
        },
        {
          title: "EOSIO Developer Portal",
          href: "https://developers.eos.io/",
          external: true,
        },
      ],
    },
    {
      header: "Downloads",
      links: [
        {
          title: "Whitepaper",
          href: "/files/EPN-Whitepaper.pdf",
          download: true,
        },
        {
          title: "Press Kit",
          href: "/files/Press-kit.zip",
          download: true,
        },
      ],
    },
  ],
};

export default FooterDetails;
