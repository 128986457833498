import React from "react";

const BlogHeader = ({ title, excerpt }) => {
  return (
    <header className="mb-8">
      <div className="text-center">
        <h1 className="h1 mb-4" data-aos="fade-up">
          {title}
        </h1>
        <p
          className="text-xl text-gray-400"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {excerpt}
        </p>
      </div>
    </header>
  );
};

export default BlogHeader;
