const targetSectionDetails = {
  targetHeader: {
    title: "How to join",
    image: require("../images/target-image.png"),
    tagline: "Be the change you want to see",
  },
  targetDetails: [
    {
      title: "EOS users",
      description:
        "Power Users enjoy new account capabilities and earn Power Network tokens. \
        Registration takes 2 minutes.",
      cta: {
        enabled: false,
        text: "Register",
        href: "#",
      },
    },
    {
      title: "Organizations",
      description:
        "Offer subscription products and other services using the EOS Power \
        Network. Very little coding required.",
      cta: {
        enabled: false,
        text: "Learn how",
        href: "#",
      },
    },
    {
      title: "EOS block producers",
      description: "Earn votes, earn revenue, and contribute to something big.",
      cta: {
        enabled: false,
        text: "Learn more",
        href: "#",
      },
    },
  ],
};

export default targetSectionDetails;
