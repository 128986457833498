import React from "react";

import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import Tabs from "../partials/Tabs";
// import News from "../partials/News";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Stats from "../partials/Stats";
import Whitepaper from "../partials/Whitepaper";

import blockSectionDetails from "../epn-details/BlockSectionDetails";
import whyEOSTabsDetails from "../epn-details/WhyEOSTabsDetails";
import statsSectionDetails from "../epn-details/StatsSectionDetails";
import targetSectionDetails from "../epn-details/TargetSectionDetails";
import SpecFooter from "../specifics/SpecFooter";
import SpecHeader from "../specifics/SpecHeader";

const { blocksHeader, blocksDetails, blocksCta } = blockSectionDetails;
const { tabsHeader, tabsDetails } = whyEOSTabsDetails;
const { statsHeader, statsDetails, statsCta } = statsSectionDetails;

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <SpecHeader />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />

        <FeaturesBlocks
          sectionHeader={blocksHeader}
          sectionDetails={blocksDetails}
          cta={blocksCta}
        />

        <Stats
          sectionHeader={statsHeader}
          sectionDetails={statsDetails}
          cta={statsCta}
        />

        {/* <Target sectionHeader={targetHeader} sectionDetails={targetDetails} /> */}

        <Tabs
          topComponent={false}
          sectionHeader={tabsHeader}
          sectionDetails={tabsDetails}
        />

        {/*<News />          /* Will add someday*/}
        <Whitepaper />
      </main>

      {/*  Site footer */}
      <SpecFooter />
    </div>
  );
}

export default Home;
