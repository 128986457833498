import React from "react";

import PageIllustration from "../partials/PageIllustration";
import BlogSingle from "../partials/BlogSingle";
import Whitepaper from "../partials/Whitepaper";
import SpecFooter from "../specifics/SpecFooter";
import Tokenomics from "../epn-details/Blogs/Tokenomics";
import constants from "../epn-details/Constants";
import SpecHeader from "../specifics/SpecHeader";

function TokenDesign({ content }) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <SpecHeader />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <BlogSingle content={<Tokenomics />} social={constants.social} />
      </main>

      {/*  Site footer */}
      <Whitepaper />
      <SpecFooter />
    </div>
  );
}

export default TokenDesign;
