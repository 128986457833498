import { BiDonateHeart } from "react-icons/bi";
import { BsClockHistory, BsFillLightningFill } from "react-icons/bs";

import constants from "./Constants.js";
const size = constants.iconSize;

const blockSectionDetails = {
  blocksHeader: {
    title: "DeFi needs pull transactions",
    description:
      "Without pull transactions, many traditional finance contracts are impossible",
  },

  blocksDetails: [
    {
      icon: <BiDonateHeart size={size} />,
      header: "Donations",
      description:
        "Recurring donations automate charitable giving to the creators & organizations you love.",
    },
    {
      icon: <BsClockHistory size={size} />,
      header: "Subscriptions",
      description:
        "Think about how many subscriptions you have. Netflix? Spotify? Membership fees?",
    },
    {
      icon: <BsFillLightningFill size={size} />,
      header: "Auto-Pay",
      description:
        "Are you using auto-pay for your bills? Utilities? Loan payments?",
    },
  ],

  blocksCta: {
    btnText: "Learn how it works",
    href: "/how-it-works",
  },
};

export default blockSectionDetails;
