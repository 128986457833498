import React from "react";

import PageIllustration from "../partials/PageIllustration";
import Whitepaper from "../partials/Whitepaper";
import FeaturesZigzag from "../partials/FeaturesZigzag";
import PowerCycleDetails from "../epn-details/PowerCycleDetails";

import SpecFooter from "../specifics/SpecFooter";
import SpecHeader from "../specifics/SpecHeader";

function PowerCycle() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <SpecHeader />

      <main className="flex-grow">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <FeaturesZigzag topComponent={true} details={PowerCycleDetails} />

        <figure
          className="mb-8 max-w-6xl mx-auto px-4 sm:px-6"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <img
            // className="w-full"
            src={require("../images/PowerCycle.png").default}
            alt="Power Cycle Illustration"
          />
        </figure>

        {/* <TestimonialsCarousel /> */
        /* This is the section with the globe image as well. Maybe someday */}
        {/* <Career /> */
        /* Maybe someday? */}
        {/* <Clients /> */
        /* I think in the future this could show BPs who have joined */}

        <Whitepaper />
      </main>

      {/*  Site footer */}
      <SpecFooter />
    </div>
  );
}

export default PowerCycle;
