const headerDetails = {
  mainLinks: [
    { to: "/how-it-works", title: "How it Works" },
    { to: "/power-cycle", title: "Power Cycle" },
    { to: "/tokenomics", title: "Tokenomics" },
    { to: "/roadmap", title: "Roadmap" },
    //{ to: "/faq", title: "FAQ"},
    //{ to: "/blog", title: "Blog" },
  ],
  configuration: {
    showLogin: false,
    showPomelo: true,
  },
};

export default headerDetails;
